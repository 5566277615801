import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import moment from 'moment';
import PreviewCards from "./PreviewCards";
import { Spinner } from "reactstrap";

export default function Home() {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    //logic for page load
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({});

    const hours = {
        "4": 18, //T1 - Facility Training
        "5": 192, //T5 - Existing Driver/Operator Training
        "7": 12, //T3.2 - Officer Continuing Education
        "10": 12, //T5 - Existing Driver/Operator Training
        "11": 6, //T6 - Hazardous Materials Training
        "12": 240, //T7 - Recruit Training Program
        "196": 24, //Fire Inspector
        "197": 10, //Public Educator
        "198": 40, //Fire Investigator
    }

    const getData = async () => {        
        try{
            var start = moment().startOf('year').format('YYYY-MM-DD');
            var end = moment().endOf('year').format('YYYY-MM-DD');
            await axiosPrivate.get(`/api/analytics/isorecords/preview?startDate=${start}&endDate=${end}`)
            .then(response =>{
                const dictionary = response.data.reduce((obj, item) => {
                    obj[item.code] =
                    {
                        hoursRequired: item.id in hours ? hours[item.id]  : 0,
                        hoursCompleted: item.value,
                        percentageCompleted: item.id in hours ? Math.min(item.value / hours[item.id] * 100, 100) : 100
                    };
                    return obj;
                }, {});

                setData(dictionary);

                setLoading(false);
            });
        }
        catch(err){
            console.log(err);
            navigate('/unauthorized')
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        loading ? <Spinner variant="primary" animation="border" role="status" className='custom-spinner'/> :
        <PreviewCards data={data}/>
    );
}
