import { Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './components/Unauthorized';
import Home from './components/Home';
import './App.css';
import Dashboard from './components/Dashboard';

function App() {
  return (
    <Routes>
          <Route path='/login' element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route element={<RequireAuth />}>
              <Route path='/' element={<Home />} />
              <Route path='/dashboard' element={<Dashboard />} />
          </Route>
    </Routes>
  );
}

export default App;
