import React, { useState } from 'react';
import '../iso-dashboard.css'
import { Doughnut } from 'react-chartjs-2';

export default function DashboardCards({ data, onClick }) {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleClick = (code) => {
    onClick(code);
    setSelectedDiv(code);
  };

  const options = {
    cutoutPercentage: 50,
    cutout: '80%',
  
    plugins: {
      tooltip: {
        // disable tooltips for the doughnut
        filter: function (tooltipItem, data) {
            return false;
        }
      },
      legend: {
        display: false,
      }
    },
  };

  return (
    <div className="iso-dashboard-tiles-container">
      {Object.entries(data).map(([key, value]) => (
        <div key={key} className={`iso-dashboard-tile expanded-version ${selectedDiv === key ? 'expanded-version-active' : ''}`} onClick={() => handleClick(key)}>
          <div className="iso-dashboard-expanded-text-content">
            <h4 className="iso-dashboard-tile-title">{key}</h4>
            <div className="iso-dashboard-expanded-text-content-divider"></div>
            <span className="iso-dashboard-hours-completed">{value.hoursCompleted} Hours Completed</span>
          </div>

          {value.hoursRequired !== 0 ? (
            <div className="iso-dashboard-tile-progress-ring-container">
              <Doughnut data={{
                labels: ['Progress', 'Remaining'],
                datasets: [
                  {
                    data: [value.percentageCompleted, 100 - value.percentageCompleted],
                    backgroundColor: ['#FEDE17', '#000000'],
                    borderWidth: 0
                  }
                ]
              }} options={options} />

              <div className='iso-dashboard-doughnut-label'>{value.percentageCompleted.toFixed(0)} %</div>
            </div>
          ) : (
            <p></p>
          )}


        </div>
      ))}
    </div>
  );
};